.main {
  &.toggler {
    .add-action {
      .icon {
        width: 100%;
        height: 1.5rem;
        // padding-left: 0.65rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}

.add-action {
  display: flex;
  margin-bottom: 1.375rem;
  padding-top: 0.375rem;
  align-items: center;
  justify-content: center;

  .icon {
    cursor: pointer;
    width: 3.125rem;
    height: 3.125rem;
    padding: 0;
    border: none;
    background-color: transparent;

    @media (hover: hover) {
      &:hover {
        svg {
          fill: var(--primary-dark);
        }
      }
    }

    svg {
      fill: var(--primary);
      width: 3.125rem;
      height: 3.125rem;
    }
  }
}

/* Mobile Styles */
@media (max-width: 1023px) {
  .main {
    &.toggler {
      .add-action {
        margin-bottom: 1.375rem;

        .icon {
          width: 3rem;
          height: 3rem;
          // padding-left: 0;

          svg {
            width: 3rem;
            height: 3rem;
          }
        }
      }
    }
  }

  .add-action {
    margin-bottom: 1.75rem;

    .icon {
      width: 100%;
      height: 1.5rem;
      // padding-left: 0.65rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}