.article-header {
  display: flex;
  align-items: center;
  padding: 0 5%;
  height: rem(40px);
  border-bottom: 1px solid var(--divider-light);
  background-color: var(--bg);
  position: sticky;
  top: 0;
  z-index: 1;

  >.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: rem(5px);

    img {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: $border-radius-sm;
    }

    .placeholder {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: $border-radius-sm;
      background-color: var(--bg-medium);
    }
  }

  >.title {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    color: var(--primary);
  }

  >.action {
    display: flex;
    align-items: stretch;
    align-self: stretch;

    >* {
      display: flex;
      align-items: center;
      padding: 0 rem(10px);
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          background-color: var(--bg-light);
        }
      }

      >svg {
        width: 1.25rem;
        height: 1.25rem;
      }

      &.disabled {
        cursor: not-allowed;

        @media (hover: hover) {
          &:hover {
            background-color: transparent;
          }
        }

        > svg {
          fill: var(--svg-medium);
        }
      }
    }

    .loader {
      display: flex;
      align-items: center;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}

.article-content {
  padding: 1.25rem 5%;
  font-size: 1rem;
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;

  .no-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    color: var(--text-light);
  }

  .title {
    display: flex;
    margin-bottom: 0.75rem;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 1.875rem;
      line-height: 2.375rem;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }

    .icon {
      width: rem(40px);
      height: rem(40px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      background-color: var(--bg-medium);
      border-radius: $border-radius;
      margin-right: 0.625rem;

      img {
        max-width: rem(40px);
        max-height: rem(40px);
      }

      .placeholder {
        width: rem(40px);
        height: rem(40px);
      }

      .play,
      .pause {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(4px);
        background-color: rgba(0, 0, 0, 0.55);
        border-radius: $border-radius-circle;
        cursor: pointer;

        svg {
          fill: var(--svg-light);
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  .meta {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: var(--text-light);
    border-bottom: 1px solid var(--divider-light);

    .author {
      margin-right: rem(10px);
    }

    .author,
    .info {
      display: flex;
      margin-bottom: 0.375rem;

      >a,
      >time,
      >div,
      >span {
        display: flex;
        align-items: center;
        margin-right: rem(10px);

        >svg {
          cursor: pointer;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  .attachments {
    margin: auto;
    width: 100%;

    video,
    iframe {
      width: 100%;
      height: rem(450px);
    }
  }

  .content {
    line-height: 1.75rem;

    video,
    iframe {
      display: block;
      border: 0;
      margin: auto;
      margin-bottom: rem(10px);
      width: 100%;
      height: rem(450px);
    }
  }

  figure {
    text-align: center;
    outline: none;
    margin: rem(40px auto);

    a {
      display: block;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

/* 4K Styles */
@media (min-width: 1441px) {
  .article-content {
    .attachments {

      video,
      iframe {
        height: rem(550px);
      }
    }

    .content {

      video,
      iframe {
        max-height: rem(550px);
      }
    }
  }
}

/* Mobile Styles */
@media (max-width: 1023px) {
  .article-header {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }

  .article-content {
    padding-left: 0.875rem;
    padding-right: 0.875rem;

    .title {
      h1 {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .article-content {
    .attachments {

      video,
      iframe {
        height: rem(350px);
      }
    }

    .content {

      video,
      iframe {
        max-height: rem(350px);
      }
    }
  }
}

@media (max-width: 480px) {
  .article-content {
    .meta {
      flex-direction: column;

      .author {
        margin-bottom: rem(2px);
      }
    }

    .attachments {

      video,
      iframe {
        height: rem(200px);
      }
    }

    .content {

      video,
      iframe {
        max-height: rem(200px);
      }
    }
  }
}