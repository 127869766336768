.plans {
  text-align: center;
  padding: 2.5rem 0;

  >h1 {
    font-size: 3rem;
    margin: 1rem 0;
    padding: 0;
  }

  >p {
    font-size: 1.8rem;
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
    color: var(--text-medium);
  }

  .pricing {
    display: flex;
    justify-content: center;

    .plan {
      width: rem(260px);
      padding: rem(25px) rem(20px);
      margin: 0 rem(10px);
      border: 1px solid var(--divider-light);
      border-radius: $border-radius;
      text-align: center;
      box-shadow: 0rem 0rem 0.625rem 0.375rem var(--box-shadow);

      .info {
        * {
          text-align: center;
          margin-bottom: rem(8px);
        }

        >h2 {
          margin: 0;
          margin-bottom: rem(20px);
        }

        .price {
          font-size: rem(20px);
          font-weight: 600;
        }
        
        >.slogan {
          color: var(--text-medium);
        }
      }

      .button {
        text-align: center;
        margin-top: rem(20px);
        .btn {
          display: inline-block;
          margin: auto 0 rem(5px) 0;
          text-transform: none;
        }
      }

      .date {
        color: var(--danger);
        font-weight: 600;
        padding: rem(6px);
        height: rem(52px);
        line-height: rem(20px);
      }

      .desc {
        margin-top: rem(6px);
        margin-bottom: rem(10px);
        padding-top: rem(10px);
        color: var(--text-medium);
        border-top: solid 1px var(--divider-light);
      }
    }
  }
}

/* Mobile Styles */
@media (max-width: 1023px) {
  .plans {
    padding: 0 1rem;

    >h1 {
      font-size: 1.75rem;
    }
  
    >p {
      font-size: 1rem;
    }

    .pricing {
      flex-direction: column;
      padding: 0;

      .plan {
        margin: 0;
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }
}