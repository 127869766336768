.article-item {
	width: 100%;
	display: flex;
	justify-content: space-between;
	text-decoration: none;
	border-bottom: 1px solid var(--divider-light);
	padding: 0.375rem 0.625rem;
	line-height: 1.25rem;
	user-select: none;

	@media (hover: hover) {
		&:hover {
			text-decoration: none;
			background-color: var(--bg-light);
		}
	}

	&.visited {
		.right {
			h4 {
				color: var(--text-medium);
			}
		}
	}

	&.active {
		background-color: var(--primary);

		* {
			color: var(--text) !important;
		}

		.remove {
			svg {
				fill: var(--svg-light) !important;
			}

			@media (hover: hover) {
				&:hover {
					svg {
						fill: var(--svg-medium) !important;
					}
				}
			}
		}
	}

	.left {
		margin-top: 0.1875rem;
		margin-bottom: 0.1875rem;
		margin-right: 0.625rem;
		position: relative;

		.unread {
			position: absolute;
			background-color: var(--primary);
			border-radius: $border-radius-circle;
			height: rem(8px);
			width: rem(8px);
			left: rem(34px);
			top: rem(-2px);
		}

		.icon {
			width: rem(40px);
			height: rem(40px);
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			background-color: var(--bg-medium);
			border-radius: $border-radius;

			img {
				max-width: rem(40px);
				max-height: rem(40px);
			}

			.placeholder {
				width: rem(40px);
				height: rem(40px);
			}

			.play,
			.pause {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: rem(4px);
				background-color: rgba(0, 0, 0, 0.55);
				border-radius: $border-radius-circle;

				svg {
					fill: var(--svg-light);
					width: 1.25rem;
					height: 1.25rem;
				}
			}
		}
	}

	.right {
		position: relative;
		flex-grow: 1;
		overflow: hidden;

		h4 {
			margin: 0 1.25rem 0 0;
			padding: 0;
			font-size: 0.875rem;
			font-weight: 600;
			color: var(--text-dark);
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.desc {
			margin-top: 0.25rem;
			color: var(--text-medium);
			font-size: 0.813rem;
			line-height: 1.125rem;
			max-height: 2.25rem;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.meta {
			display: flex;
			padding-top: 0.125rem;

			.time,
			.feed {
				font-size: 0.75rem;
				color: var(--text-light);
			}

			.time {
				margin-right: rem(6px);
			}

			.feed {
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 100%;
			}
		}

		.remove {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			right: rem(-4px);
			padding: rem(2px);

			svg {
				fill: var(--svg);
				width: 0.875rem;
				height: 0.875rem;
			}

			@media (hover: hover) {
				&:hover {
					svg {
						fill: var(--svg-dark);
					}
				}
			}
		}
	}
}