.btn {
	border: none;
	cursor: pointer;
	font-size: rem(14px);
	font-weight: 600;
	min-height: min-content;
	border-radius: rem(50px);
	outline: none;
	overflow: visible;
	padding: 0.375rem 2rem;
	color: var(--text-dark);
	text-decoration: none;
	background-color: var(--bg-light);

	@media (hover: hover) {
		&:hover {
			color: var(--text-dark);
			text-decoration: none;
		}
	}

	&:disabled {
		cursor: not-allowed;
		background-color: var(--bg-light);
		color: var(--text-light);
	}

	&.primary {
		background-color: var(--primary);
		color: var(--text);

		@media (hover: hover) {
			&:hover {
				background-color: var(--primary-dark);
			}
		}

		&:disabled {
			background-color: var(--bg-light);
			color: var(--text-light);
		}
	}

	&.delete {
		color: var(--text);
		background-color: var(--danger);

		@media (hover: hover) {
			&:hover {
				background-color: var(--danger-dark);
			}
		}

		&:disabled {
			background-color: var(--bg-light);
			color: var(--text-light);
		}
	}

	&.link {
		background-color: transparent;
		box-shadow: none;
		color: var(--primary);

		&.cancel {
			color: var(--text-light);

			@media (hover: hover) {
				&:hover {
					background-color: var(--bg-light);
				}
			}
		}
		
		&.text {
			padding: 0;
		}
	}
}