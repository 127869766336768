.share {
	flex: 1 1;
	display: flex;
	width: 100%;
	overflow: hidden;
	position: relative;
	
	.share-scrolling {
		width: 100%;
		height: 100%;
		overflow: auto;

		audio {
			height: rem(40px) !important;

			&:focus {
				outline: none;
			}
		}

		.article-header {
			position: initial;
			border-bottom: none;
			height: rem(50px);
		}

		.article-content {
			padding-top: 0;

			.loader {
				margin-top: 1.25rem;
			}
		}
	}
}