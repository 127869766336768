.main {
  &.toggler {
    .feeds {
      .loader {
        justify-content: left;
        padding-left: 0.75rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      .folders {
        >a {
          padding: 0;

          .icon {
            height: 2rem;
            width: 100%;
            justify-content: left;
            padding-left: 0.775rem;
          }
        }

        >ul {
          >li {
            >a {
              padding-left: 0.775rem;
            }
          }
        }
      }
    }
  }
}

.feeds {
  overflow-y: auto;
  overflow-x: hidden;
}

.feeds,
.folders {
  flex: 1;

  .loader {
    padding: rem(5px 0);
  }

  .no-content {
    padding: 1.25rem 0.625rem;
    text-align: center;
    color: var(--text-light);
  }

  >ul {
    list-style: none;
    margin: 0;
    padding: rem(5px 0);

    >li {
      @media (hover: hover) {
        &:hover {
          background-color: var(--bg-light);
        }
      }

      &.active {
        background-color: var(--primary);

        >a {
          >* {
            color: var(--text);
          }

          svg {
            fill: var(--svg-light);
          }

          .count {
            display: none;
          }

          .action {
            display: flex;
          }
        }
      }

      >a {
        display: flex;
        align-items: center;
        padding: 0.375rem 0.775rem;
        color: var(--text-dark);
        text-decoration: none;
        user-select: none;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.25rem;
          height: 1.25rem;
          margin-right: rem(5px);

          img {
            width: 1.25rem;
            height: 1.25rem;
            border-radius: $border-radius-sm;
            text-indent: -9999px;
          }

          .placeholder {
            width: 1.25rem;
            height: 1.25rem;
            border-radius: $border-radius-sm;
            background-color: var(--bg-medium);
          }
        }

        .title {
          height: 1.25rem;
          line-height: 1.25rem;
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: 0;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }

        .count,
        .action {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 2.375rem;
          height: 1.25rem;
          color: var(--text-medium);
          text-align: right;
        }

        .action {
          display: none;

          svg {
            margin-right: rem(-2px);

            @media (hover: hover) {
              &:hover {
                fill: var(--primary-light);
              }
            }
          }
        }
      }
    }

    .folders {
      @media (hover: hover) {
        &:hover {
          background-color: transparent;
        }
      }

      >a {
        padding-left: 0.775rem;

        @media (hover: hover) {
          &:hover {
            background-color: var(--bg-light);
          }
        }

        .icon {
          .custom-icon {
            position: relative;
            width: 1.25rem;
            height: 1.25rem;

            &.arrow-down,
            &.arrow-right {
              &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border: 4px solid transparent;
                pointer-events: none;
              }
            }

            &.arrow-down {
              &:before {
                border-top-color: var(--divider-medium);
                left: 50%;
                margin-left: rem(-4px);
                bottom: rem(-8px);
              }
            }

            &.arrow-right {
              &:before {
                border-left-color: var(--divider-medium);
                top: 50%;
                margin-top: rem(-4px);
                right: rem(-8px);
              }
            }

            >img {
              width: 100%;
              height: 100%;
              border-radius: $border-radius-sm;
              border: 1px solid var(--divider-medium);
              background-color: #fff;
            }
          }
        }

        &.active {
          color: var(--text);
          background-color: var(--primary);

          >* {
            color: var(--text);
          }

          svg {
            fill: var(--svg-light);
          }

          .icon {
            .custom-icon {
              &.arrow-down {
                &:before {
                  border-top-color: var(--svg-light);
                }
              }

              &.arrow-right {
                &:before {
                  border-left-color: var(--svg-light);
                }
              }

              >img {
                border-color: var(--svg-light);
              }
            }
          }

          .count {
            display: none;
          }

          .action {
            display: flex;
          }
        }
      }

      >ul {
        display: none;

        &.open {
          display: block;
          padding: 0;
        }

        >li {
          >a {
            padding-left: 2.375rem;
          }
        }
      }
    }
  }
}

/* Mobile Styles */
@media (max-width: 1023px) {
  .main {
    &.toggler {
      .feeds {
        .loader {
          justify-content: center;
          padding-left: 0;

          svg {
            width: rem(36px);
            height: rem(36px);
          }
        }

        .folders {
          >a {
            padding: 0.375rem 0.775rem !important;

            .icon {
              height: auto;
              width: auto;
              justify-content: initial;
              padding-left: 0;
            }
          }

          >ul {
            >li {
              >a {
                padding-left: 2.375rem !important;
              }
            }
          }
        }
      }
    }
  }

  .feeds {
    .loader {
      justify-content: left;
      padding-left: 0.75rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    .folders {
      >a {
        padding: 0 !important;

        .icon {
          height: 2rem;
          width: 100%;
          justify-content: left;
          padding-left: 0.775rem;
        }
      }

      >ul {
        >li {
          >a {
            padding-left: 0.775rem !important;
          }
        }
      }
    }
  }
}