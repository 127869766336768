.navbar {
  padding: 2.5rem 3rem;

  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: rem(128px);
      }
    }

    .navbar-nav {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-left: 1rem;

        .btn {
          text-transform: none;

          &.primary {
            @media (hover: hover) {
              &:hover {
                background-color: var(--primary-dark);
              }
            }
          }
        }
      }
    }
  }
}

.jumbotron {
  text-align: center;
  color: var(--text-dark);
  padding: 0 15rem;

  h1 {
    font-size: 3rem;
    margin: 1rem 0;
    padding: 0;
  }

  p {
    font-size: 1.8rem;
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
    color: var(--text-medium);
  }

  .btn {
    display: inline-block;
    font-size: 1rem;
    text-transform: none;

    &.primary {
      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          background-color: var(--primary-dark);
        }
      }
    }
  }
}

.features {
  padding: 2.5rem 3rem;

  .screenshot {
    img {
      width: 100%;
      border: 1px solid #ededed;
      border-radius: $border-radius;
      box-shadow: rem(0px 0px 10px 6px) var(--box-shadow);
    }
  }

  .feature {
    list-style: none;
  }
}

.footer {
  color: var(--text-medium);
  text-align: center;
  padding: 3rem 0;
}

/* Mobile Styles */
@media (max-width: 1023px) {
  .navbar {
    padding: 2rem 1.5rem;

    .navbar-container {
      .logo {
        width: rem(40px);
        overflow: hidden;
      }

      .navbar-nav {
        li {
          margin-left: 0.5rem;

          .btn {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
          }
        }
      }
    }
  }

  .jumbotron {
    text-align: center;
    color: var(--text-dark);
    padding: 0 1.5rem;

    h1 {
      font-size: 1.75rem;
      margin: 1rem 0;
    }

    p {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }

  .features {
    padding: 1.5rem;
  }

  .footer {
    padding: 1.5rem 0;
  }
}