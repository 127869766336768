.modal {
	animation: 0.5s modal-zoom-in;
	background-color: var(--bg);
	border-radius: $border-radius;
	box-shadow: $box-shadow;
	border: 1px solid var(--divider);
	padding: rem(10px 24px 24px 24px);
	position: relative;
	width: rem(520px);
	margin: 3.75rem 0;

	&:focus {
		outline: 0;
	}

	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: rem(10px 0 20px 0);

		h1 {
			font-size: rem(20px);
			font-weight: 600;
			margin: 0;
			padding: 0;
		}

		.exit {
			cursor: pointer;
			display: flex;
			align-items: center;

			svg {
				width: 1.25rem;
				height: 1.25rem;
			}
		}
	}

	form,
	section {
		padding: rem(0 2px);
	}

	.buttons {
		display: flex;
		flex-direction: row-reverse;
		margin-top: rem(15px);

		.btn {
			margin-left: rem(10px);
		}
	}
}

.modal-overlay {
	align-items: center;
	background-color: var(--modal-overlay);
	border: none;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1001;
	overflow: auto;
}

@keyframes modal-zoom-in {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

/* Mobile Styles */
@media (max-width: 1023px) {
	.modal {
		width: 90%;
		padding: rem(5px 20px 20px);
	}
}